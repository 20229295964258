import { CookiesResource } from '@/api/resources/CookiesResource'
const state ={
    types: new CookiesResource({
        necessaryConsent: true,
        functionalConsent: false,
        analyticConsent: false,
        advertisingConsent: false,
    }),
    sessionId: null,
    currentView: 'CookiesInfo',
    activePopup: false,
}
const mutations = {
    setTypes(state, types) {
        state.types = new CookiesResource(types)
    },
    setCurrentView(state, currentView) {
        state.currentView = currentView
    },
    setActivePopup(state, value) {
        state.activePopup = value
    },
    setSessionId(state, sessionId) {
        state.sessionId = sessionId
    },
}
const getters = {
    getTypes: (state) => state.types,
    getCurrentView: (state) => state.currentView,
    getSessionId: (state) => state.sessionId,
    getActivePopup: (state) => state.activePopup,
}
export default {
    state,
    mutations,
    getters,
}
