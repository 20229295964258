<template>
    <div :class="$style.settings">
        <div :class="$style.head">
            {{ $t('cookies.settingsTitle') }}
        </div>
        <div :class="$style.text">
            {{ $t('cookies.settingsDescription') }}
        </div>
        <div :class="$style.content">
            <div :class="$style.item">
                <CookiesCheckbox
                    v-model="types.necessaryConsent"
                    disabled
                    :label="$t('cookies.settingsNecessaryLabel')"
                    name="necessary"
                />
                <div :class="$style.info">
                    {{ $t('cookies.settingsNecessaryDescription') }}
                </div>
            </div>
            <div :class="$style.item">
                <CookiesCheckbox
                    v-model="types.functionalConsent"
                    :label="$t('cookies.settingsFunctionalLabel')"
                    name="functional"
                />
                <div :class="$style.info">
                    {{ $t('cookies.settingsFunctionalDescription') }}
                </div>
            </div>
            <div :class="$style.item">
                <CookiesCheckbox
                    v-model="types.analyticConsent"
                    :label="$t('cookies.settingsEfficiencyLabel')"
                    name="efficiency"
                />
                <div :class="$style.info">
                    {{ $t('cookies.settingsEfficiencyDescription') }}
                </div>
            </div>
            <div :class="$style.item">
                <CookiesCheckbox
                    v-model="types.advertisingConsent"
                    :label="$t('cookies.settingsAdvertisingLabel')"
                    name="advertising"
                />
                <div :class="$style.info">
                    {{ $t('cookies.settingsAdvertisingDescription') }}
                </div>
            </div>
        </div>
        <div :class="$style.bottom">
            <div :class="$style.info">
                {{ $t('cookies.settingsBottomText') }}
                <a :href="$t('cookies.policyLink')" :class="$style.link">
                    {{ $t('cookies.settingsBottomPolicyText') }}
                </a>
            </div>
            <div :class="$style.action">
                <button :class="$style.button" @click="onSave">
                    <span>{{ $t('cookies.saveAndContinued') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { CookiesResource } from '@/api/resources/CookiesResource'
import GtmConsentsMixin from '@/mixins/GtmConsentsMixin'
import CookiesCheckbox from './CookiesCheckbox.vue'
import UuidMixin from '@/mixins/UuidMixin'
export default {
    components: { CookiesCheckbox },
    mixins: [GtmConsentsMixin, UuidMixin],
    data() {
        return {
            types: new CookiesResource(),
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            cookiesTypes: 'getTypes',
            sessionId: 'getSessionId'
        }),
    },
    mounted() {
        this.$nextTick(() => {
            this.types = new CookiesResource(this.cookiesTypes)
        })
    },
    methods: {
        ...mapMutations({
            setCookiesTypes: 'setTypes',
            setSessionId: 'setSessionId'
        }),
        async onSave() {
            this.loading = true
            if (this.sessionId) {
                await this.putCookies()
            } else {
                await this.postCookies()
            }
        },
        putCookies() {
            this.setCookiesTypes(this.types)
            this.$cookies.set(
                'cookiesTypes',
                this.types,
                60 * 60 * 24 * 365,
                '/',
                process.env.VUE_APP_COOKIES_DOMAIN,
                true,
                'None'
            )
            this.pushDataLayer('consent', 'update', {
                ad_storage: this.types.advertisingConsent
                    ? 'granted'
                    : 'denied',
                ad_user_data: this.types.advertisingConsent
                    ? 'granted'
                    : 'denied',
                ad_personalization: this.types.advertisingConsent
                    ? 'granted'
                    : 'denied',
                analytics_storage: this.types.analyticConsent
                    ? 'granted'
                    : 'denied',
                functionality_storage: this.types.functionalConsent
                    ? 'granted'
                    : 'denied',
                personalization_storage: this.types.functionalConsent
                    ? 'granted'
                    : 'denied',
                security_storage: this.types.necessaryConsent
                    ? 'granted'
                    : 'denied',
            })
            this.$emit('onAccept')
        },
        postCookies() {
            const uuid = this.sessionId ? this.sessionId : this.create_UUID()
            this.setCookiesTypes(
                new CookiesResource({
                    necessaryConsent: this.types.necessaryConsent,
                    functionalConsent: this.types.functionalConsent,
                    analyticConsent: this.types.analyticConsent,
                    advertisingConsent: this.types.advertisingConsent,
                })
            )
            this.$cookies.set(
                'cookiesTypes',
                {
                    necessaryConsent: this.types.necessaryConsent,
                    functionalConsent: this.types.functionalConsent,
                    analyticConsent: this.types.analyticConsent,
                    advertisingConsent: this.types.advertisingConsent,
                },
                60 * 60 * 24 * 365,
                '/',
                process.env.VUE_APP_COOKIES_DOMAIN,
                true,
                'None'
            )
            this.$cookies.set(
                'cookiesAcceptedUuid',
                uuid,
                60 * 60 * 24 * 365,
                '/',
                process.env.VUE_APP_COOKIES_DOMAIN,
                true,
                'None'
            )
            this.pushDataLayer('consent', 'update', {
                ad_storage: this.types.advertisingConsent
                    ? 'granted'
                    : 'denied',
                ad_user_data: this.types.advertisingConsent
                    ? 'granted'
                    : 'denied',
                ad_personalization: this.types.advertisingConsent
                    ? 'granted'
                    : 'denied',
                analytics_storage: this.types.analyticConsent
                    ? 'granted'
                    : 'denied',
                functionality_storage: this.types.functionalConsent
                    ? 'granted'
                    : 'denied',
                personalization_storage: this.types.functionalConsent
                    ? 'granted'
                    : 'denied',
                security_storage: this.types.necessaryConsent
                    ? 'granted'
                    : 'denied',
            })
            this.pushDataLayerEvent('cookie_consent_update')
            this.$emit('onAccept')
        },
    },
}
</script>
<style lang="scss" module>
.settings {
    max-width: 1052px;
    width: 100%;
    font-weight: 400;
    .head {
        font-size: 1.8rem;
        font-weight: 700;
    }
    .text {
        font-size: 1.6rem;
    }
    .head,
    .text {
        margin-bottom: 20px;
    }
    .content {
        margin-bottom: 24px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 54px;
        .item {
            .info {
                margin-top: 14px;
                font-size: 1.2rem;
            }
        }
    }
    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .info {
            font-size: 1.4rem;
        }
        .link {
            text-decoration: underline;
        }
    }
    .action {
        .button {
            cursor: pointer;
            text-align: center;
            color: #ffffff;
            background-color: #ed544e;
            border: unset;
            font-size: 1.8rem;
            padding: 13px 26px;
            transition: background-color 0.3s;
            &:hover {
                background-color: #2f3031;
            }
        }
    }
    @media (max-width: 1024px) {
        .content {
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;
        }
        .bottom {
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
        }
    }
    @media (max-width: 640px) {
        .content {
            grid-template-columns: 1fr;
        }
    }
    @media (max-width: 500px) {
        &-content {
            &-item {
                width: 100%;
                margin-right: 0;
            }
        }
    }
}
</style>
