<template>
    <div class="consent-window">
        <transition name="component-fade" mode="out-in">
            <component
                :is="currentView"
                @onSettings="switchOnSettings"
                @onAccept="$emit('onAccept')"
            />
        </transition>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import CookiesInfo from './CookiesInfo'
import CookiesSettings from './CookiesSettings'
export default {
    components: {
        CookiesInfo,
        CookiesSettings,
    },
    props: {
        currentView: {
            type: String,
            default: 'CookiesInfo',
        },
    },
    methods: {
        ...mapMutations({
            setCurrentView: 'setCurrentView',
        }),
        switchOnSettings() {
            this.setCurrentView('CookiesSettings')
        },
    },
}
</script>
<style lang="scss" scoped>
.consent-window {
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color: #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.07);
    padding: 40px;
    z-index: 100;
    .component-fade-enter-active,
    .component-fade-leave-active {
        transition: opacity 0.5s ease;
    }
    .component-fade-enter,
    .component-fade-leave-to {
        opacity: 0;
    }
    @media (max-width: 1675px) {
        max-width: calc(100% - 60px);
    }
    @media (max-width: 800px) {
        padding: 20px;
        min-width: 0;
        max-width: 100%;
        max-height: calc(100% - 100px);
        left: 20px;
        right: 20px;
        transform: unset;
        bottom: 0;
        overflow: auto;
    }
}
</style>
