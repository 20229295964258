export default {
    general: {
        loading: 'Loading',
        back: 'Powrót na stronę',
        configurator: 'Konfigurator notesu',
        page: ' kartka',
        page2: ' kartek',
        pages: ' kartki',
        zoom: 'Powiększ okleinę',
        zoom2: 'Powiększ rodzaj zadruku',
        buttonView: 'Zmień widok',
        notfound: 'nie znaleziono',
    },
    material: {
        title: 'Stwórz swój Mindnotes® ',
        fsc: 'Mindnotes z certyfikowanych materiałów FSC<sup>®</sup>',
        standard: 'Mindnotes z materiałów standardowych',
        label: 'Zacznij konfigurację',
        load: 'Załaduj konfiguracje: ',
        description:
            'Podaj kod konfiguracji znajdujący się na podsumowaniu by załadować ją ponownie.',
        loadMobile: 'lub dodaj nr konfiguracji',
    },
    paper: {
        title: 'Okładka - materiał',
        filter: 'Filtruj materiały po:',
        color: 'Wybierz kolor',
        category: 'Wybierz kategorię',
        search: 'Wpisz nazwę lub nr okleiny',
        noResults: 'Brak wyników wyszukiwania.',
        search2: 'wyszukaj materiał',
        papier: 'Papier',
        okleina: 'Okleina introligatorska',
        placeholder: 'Wpisz nazwę lub numer okleiny',
        fallback: 'Brak oklein dla wybranej konfiguracji.',
    },
    config: {
        title: 'Twoja konfiguracja',
        subtitle:
            'W tym kroku możesz sprawdzić i pobrać pełną konfigurację bez cen sprzedaży.',
        details: 'Pokaż szczegóły Twojej konfiguracji',
        button1: 'Pobierz PDF',
        linkTitle: 'Link do Twojej konfiguracji',
        linkDesc:
            'Skopiuj kod bądź link w celu ponownego użycia tej samej konfiguracji w kolejnych zamówieniach.',
        button2: 'Kopiuj kod',
        button3: 'Kopiuj link',
        desc2:
            'Jeśli chcesz otrzymać ofertę z cenami rynkowymi, przejdź dalej.',
        logowanko: 'Dalej',
        copyCode: 'Skopiowano kod',
        copyLink: 'Skopiowano link',
    },
    summary: {
        title: 'Podsumowanie',
        save: 'Zapisz do PDF',
        edit: 'Edytuj',
        goToForm: 'Przejdź do formularza',
        sendForm: 'Zatwierdź i wyślij',
        info: {
            send: 'Wysyłanie formularza...',
            pdf: `W celu zapisania swojej konfiguracji do PDF kliknij przycisk poniżej.`,
            individual: `"PromoNotes realizuje wszystkie swoje zamówienia do klientów końcowych wyłącznie za pośrednictwem swoich dystrybutorów, którymi są agencje reklamowe."`,
            form: `Jeśli nie masz agencji reklamowej, z którą współpracujesz to również, naciśnij przycisk „zatwierdź i wyślij”, a my skontaktujemy Cię z dystrybutorem naszych produktów.`,
            agency: `Celem złożenia oficjalnego zapytania ofertowego i otrzymania
                    wiążącej oferty handlowej naciśnij przycisk "Przejdź do
                    oferty".`,
            erp: `"Po jego naciśnięciu opuścisz stronę internetową Mindnotes i zostaniesz przekierowany do portalu klienta PromoNotes. Tam odnajdziesz wiążącą ofertę handlową do swojej konfiguracji jak i również historię wcześniejszych zapytań ofertowych."`,
        },
    },
    pdf: {
        summary: 'Podsumowanie konfiguracji',
        configurationNo: 'Numer kalkulacji: ',
        code: 'Kod konfiguracji: ',
        link: 'link konfiguracji',
        file1: 'Dziękujemy za pomyślnie przeprowadzoną',
        file2: 'konfigurację notesu książkowego Mindnotes®',
        file3: `Wszystkich dystrybutorów produktów reklamowych zapraszamy do kontaktu z zespołem handlowym PromoNotes.  `,
        file4: `Wystarczy, że prześlesz numer Twojej konfiguracji do swojego opiekuna handlowego w PromoNotes lub na adres `,
        file5: 'biuro@promonotes.pl',
        file6: 'biuro@promonotes.pl, a my skontaktujemy się z Tobą.',
        file7: `Jeśli nie jesteś dystrybutorem produktów reklamowych prosimy skontaktuj się ze swoim dostawcą gadżetów reklamowych.`,
        file71: '',
        file72: `Jeśli nie współpracowałeś́ z dystrybutorem posiadającym w ofercie produkty Mindnotes®, wróć do konfiguratora i zarejestruj się.`,

        file73:
            'Po zarejestrowaniu skontaktujemy Cię z dystrybutorem naszych produktów.',
        file73Html: '   ',
        file8: `Podane wymiary oraz grafiki są orientacyjne i nie mogą stanowić podstawy reklamacji. Producent zastrzega sobie prawo do `,
        file9: `technicznych zmian poszczególnych produktów, a także nie ponosi odpowiedzialności za ewentualne błędy w konfiguratorze.`,
        file10: `Opis podsumowania konfiguracji oraz grafika wygenerowana w konfiguratorze nie stanowi oferty w myśl przepisów
kodeksu cywilnego.`,
        file11: '',
        footer: 'Promonotes Sp. z o.o. Wszelkie prawa zastrzeżone.',
        page: 'Strona ',
        data: 'Data: ',
        disclaimer: `
            <h6>Dziękujemy za pomyślnie przeprowadzoną konfigurację notesu książkowego Mindnotes®</h6>
            <p>
                Wszystkich dystrybutorów produktów reklamowych zapraszamy do kontaktu z zespołem handlowym PromoNotes. Wystarczy, że prześlesz numer Twojej konfiguracji do swojego opiekuna handlowego w PromoNotes lub na adres <a href="mailto:biuro@promonotes.pl"><u>biuro@promonotes.pl</u></a>, a my skontaktujemy się z Tobą.
            </p>
            <p>Jeśli nie jesteś dystrybutorem produktów reklamowych prosimy skontaktuj się ze swoim dostawcą gadżetów reklamowych.</p>
            <p>
                Jeśli nie współpracowałeś́ z dystrybutorem posiadającym w ofercie produkty Mindnotes®, przejdź dalej do rejestracji i oferty.
                Po zarejestrowaniu skontaktujemy Cię z dystrybutorem naszych produktów.
            </p>
            <p>
                Podane wymiary oraz grafiki są orientacyjne i nie mogą stanowić podstawy reklamacji. Producent zastrzega sobie prawo do technicznych zmian poszczególnych produktów, a także nie ponosi odpowiedzialności za ewentualne błędy w konfiguratorze. Opis podsumowania konfiguracji oraz grafika wygenerowana w konfiguratorze nie stanowi oferty w myśl przepisów kodeksu cywilnego.
            </p>
        `,
        pdfDisclaimer1: `
PromoNotes realizuje wszystkie swoje zamówienia do klientów końcowych wyłącznie za pośrednictwem swoich
dystrybutorów, którymi są agencje reklamowe. Prosimy, skontaktuj się ze swoim dostawcą gadżetów reklamowych.
`,
        pdfDisclaimer2: `
Jeśli nie współpracowałeś́ z dystrybutorem posiadającym w ofercie produkty Mindnotes®, wróć do konfiguratora
i dokończ rejestrację. Po zarejestrowaniu skontaktujemy Cię z dystrybutorem naszych produktów.

Podane wymiary oraz grafiki są orientacyjne i nie mogą stanowić podstawy reklamacji. Producent zastrzega
sobie prawo do technicznych zmian poszczególnych produktów, a także nie ponosi odpowiedzialności za
ewentualne błędy w konfiguratorze. Opis podsumowania konfiguracji oraz grafika wygenerowana w konfiguratorze
nie stanowi oferty w myśl przepisów kodeksu cywilnego.
`,
    },
    form: {
        form: 'Formularz',
        title: 'Rejestracja',
        fill: 'Aby otrzymać ofertę cenową, wypełnij pola formularza.',
        company: 'Nazwa firmy',
        phone: 'Numer telefonu',
        email: 'E-mail',
        website: 'Adres strony internetowej',
        street: 'Ulica',
        houseNumber: 'Numer domu/mieszkania',
        city: 'Miejscowość',
        nip: 'NIP',
        zipcode: 'Kod pocztowy',
        country: 'Kraj',
        name: 'Imię',
        surname: 'Nazwisko',
        position: 'Stanowisko',
        agencyName: 'Nazwa agencji',
        agencyEmail: 'Adres e-mail agencji',
        agencyAddress: 'Inne informacje',
        agencyInfo: `Jeśli chcesz otrzymać ofertę od swojej agencji reklamowej - wypełnij poniższe pola i naciśnij przycisk "zatwierdź i wyślij"`,
        message: 'Twoja wiadomość dotycząca zamówienia',
        emailcopy: 'Prześlij kopię konfiguracji mojego notesu na podane maile',
        consent1:
            '<span class="red">*</span> Wyrażam zgodę na przetwarzanie przez Promonotes Sp. z o.o. oraz przez podmioty współpracujące z Promonotes Sp. z o.o. moich danych osobowych zawartych w formularzu w celu udzielenia odpowiedzi na zapytanie skierowane za pomocą formularza kontaktowego. Szczegóły dotyczące przetwarzania danych określone są w zakładce RODO',
        consent2:
            'Wyrażam zgodę na otrzymywanie od PromoNotes Sp. z o.o. drogą elektroniczną na wskazany przeze mnie w Formularzu adres e-mail informacji handlowych, dotyczących produktów i usług oferowanych przez PromoNotes Sp. z o.o. w rozumieniu ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz.U. 2002 Nr 144, poz. 1204 t.j. Dz.U. z 2019 r. poz. 123). Zgoda ma charakter dobrowolny. Szczegóły dotyczące przetwarzania danych określone są w zakładce RODO',
        consent3:
            'Wyrażam zgodę na używanie telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących w rozumieniu ustawy z dnia 16 lipca 2004 r. – Prawo telekomunikacyjne (Dz.U. 2004 Nr 171, poz. 1800 t.j. Dz.U. z 2018 r. poz. 1954) w stosunku do podanego przeze mnie adresu e-mail, numeru telefonu dla celów marketingu bezpośredniego, w tym w zakresie nawiązania kontaktu, przedstawienia oferty przez PromoNotes spółka z ograniczoną odpowiedzialnością. Zgoda ma charakter dobrowolny. Szczegóły dotyczące przetwarzania danych określone są w zakładce RODO',
        send: 'Wyślij',
        description:
            'Jeśli jesteś już klientem Promonotes zaloguj się używając tych samych danych.',
        password: 'Hasło',
        password2: 'Powtórz hasło',
        login: 'Zaloguj się',
        required: 'pole obowiązkowe',
        forgotPass: 'Nie pamiętasz hasła?',
        customerType: 'Rodzaj klienta',
        individual: 'Klient końcowy',
        adAgency: 'Agencja reklamowa',
        add: 'Dodaj',
        goToOffer: 'Przejdź do oferty',
        signInAndGoToOffer: 'Załóż konto i przejdź do oferty',
        register: {
            title: 'Rejestracja',
            secondTitle: 'Twoje dane',
            send: 'Zarejestruj się',
        },
        passwordPage: {
            title: 'Nie pamiętasz hasła',
            description:
                'Podaj swój e-mail którego użyłeś przy zakładaniu konta, aby wysłać na niego link resetujący hasło.',
            label: 'E-mail',
            button: 'Wyślij',
            hello: 'Witaj',
            company: 'z firmy',
            tip:
                'W tym kroku utworzysz swoje hasło do konta. Pamiętaj aby hasło było skuteczne powinno zawierać od 8 do 16 znaków, duże litery, cyfry oraz znaki specjalne.',
            passWeak: 'Twoje hasło jest słabe',
            passGood: 'Twoje hasło jest dobre',
            passStrong: 'Twoje hasło jest silne',
            passResetTitle: 'Twoje hasło zostało zresetowane',
            passResetDesc:
                'W celu zalogowania się na swoje konto utwórz nowe hasło.',
            passResetSave: 'Zapisz',
        },
        agency: {
            noAcc: 'Nie masz jeszcze konta?',
            register:
                'Rejestracja jest konieczna w celu podania danych do realizacji zamówień. Jeśli byłeś już klientem Promonotes, twoje dane są przechowywane w naszej bazie i będziesz musiał je tylko potwiedzić.',
            createAcc: 'Załóż konto',
        },
        male: 'Pan',
        female: 'Pani',
    },
    offer: {
        title: 'Oferta',
        description:
            'Wypełnij wymagane pola, a my podamy Tobie rekomendowaną przez nas rynkową cenę sprzedaży.',
        prints: 'Nakład',
        price2: 'Cena zakupu',
        price1: 'Rekomendowana rynkowa cena sprzedaży',
        transportCost: 'Koszty transportu',
        legalInfo:
            'Podane ceny mają charakter informacyjny i nie stanowią oferty handlowej w rozumieniu Art.66 par.1 Kodeksu Cywilnego. Zastrzegamy sobie prawo zmiany podanych cen. Podane ceny są wartościami netto i nie zawierają żadnych podatków, ani opłat celnych. Celem otrzymania wiążącej oferty proszę przejdź do PODSUMOWANIA.',
        country: 'Kraj',
        countryOther:
            'W przypadku gdy nie ma Twojego kraju na liście prosimy o wybranie opcji INNY.',
        calculate: 'Oblicz',
        orientation:
            'Orientacyjny czas realizacji zamówienia wynosi 14-21 dni od daty akceptacji projektu graficznego.',
        currency: 'Waluta',
        szt: 'szt.',
        other: 'INNY',
        warning: 'Warunki dostawy',
        addPrints: 'Dodaj nakład',
        error: {
            form: `Proszę poprawnie uzupelnić pola.`,
        },
        info: {
            form: 'Proszę czekać...',
            valuation: 'Przygotowujemy Twoja ofertę...',
        },
    },
    sent: {
        title: 'Dziękujemy za zgłoszenie!',
        desc:
            'Skontaktujemy Państwa z Naszym Partnerem w możliwie najkrótszym czasie',
        button: 'Nowa konfiguracja',
    },
    modal: {
        coverSoft: 'Okładka miękka papierowa',
        coverSoftDescr:
            'Najprostsza, przyjemna dla oka, a zarazem najbardziej ekonomiczna oprawa w kolekcji Mindnotes®',
        coverHard:
            'Okładka twarda w okleinie papierowej lub introligatorskiej ',
        coverHardDescr:
            'Klasyczna twarda oprawa książkowa dostępna w szerokiej gamie oklein papierowych i materiałowych.',
        coverSoft2: 'Okładka miękka w okleinie introligatorskiej ',
        coverSoft2Descr:
            'Największa gwiazda w kolekcji Mindnotes®. Klasyczna oprawa książkowa z niezwykle elastyczną i wyłącznie materiałową okładką.',
        coverHard2: 'Okładka twarda elastyczna w okleinie introligatorskiej ',
        coverHard2Descr:
            'Klasycznie twarda ale jednocześnie przyjemnie elastyczna oprawa książkowa, oferowana w kolekcji Mindnotes® wyłącznie w opcji materiałowej.',
        cornerRound: 'Zaokrąglone narożniki',
        cornerStraight: 'Proste narożniki',
        ridgeSoft: 'Miękki grzbiet',
        ridgeHard: 'Twardy grzbiet',
        flyleaf:
            'Element notesu łączący okładkę z blokiem, zwykle wykonany z grubszego papieru niż blok notesu.',
        pocket:
            'Praktyczna kieszonka na dokumenty zamontowana na wewnętrznej stronie tylnej okładki.',
        package1:
            'Luzem - notesy pakowane są bezpośrednio do kartonów zbiorczych. Na życzenie stosowane są (pomiędzy warstwami notesów) papierowe przekładki zabezpieczające. ',
        package2:
            'Standardowe – indywidualny woreczek z foli PP, z zamknięciem samoprzylepnym lub z perforacją. ',
        package3:
            'Ekologiczne – produkt zapakowany w kompostowalną folię, wyprodukowaną ze skrobi kukurydzianej. Woreczek z perforacją i z nadrukiem (logo 100% kompostowalne). ',
        marking1:
            '<strong>Tłoczenie z folią </strong>- do 50 cm<sup>2</sup> w jednym miejscu',
        marking2:
            '<strong>Tłoczenie bez folii małe</strong> - do 50 cm<sup>2</sup> w jednym miejscu',
        marking3:
            '<strong>Tłoczenie bez folii duże </strong>- do 150 cm<sup>2</sup> w jednym miejscu',
        paperTypeSoft1: 'Najpopularniejszym materiałem na okładki standardowych notesów jest:',
        paperTypeSoft2: 'karton dwustronnie powlekany 250g/m2',
        paperTypeSoft3: 'Najpopularniejszymi materiałami na okładki ekologicznych notesów są:',
        paperTypeSoft4: 'karton niepowlekany z recyklingu kraft 300g/m2',
        paperTypeSoft5: 'papier offsetowy z recyklingu 300g/m2',
        paperTypeTorino: 'Najpopularniejszym materiałem na okładki notesów w oprawie introligatorskiej jest okleina Torino.',
        paperTypeHardVeneer1: 'Najpopularniejszym materiałem na okładki standardowych notesów jest:',
        paperTypeHardVeneer2: 'papier kredowy 150g/m2',
        paperTypeHardVeneer3: 'Najpopularniejszymi materiałami na okładki ekologicznych notesów są:',
        paperTypeHardVeneer4: 'papier offsetowy z recyklingu 160g/m2',
        paperTypeHardVeneer5: 'papier niepowlekany z recyklingu kraft 120g/m2',
    },
    errors: {
        required: 'Wybierz opcje obowiązkowe',
        cover: 'Wybierz co najmniej jedną opcję z każdej kategorii',
        format: 'Wybierz format',
        paper: 'Wybierz okleinę',
        coverMarking: 'Wybierz co najmniej jedną opcję z każdej kategorii',
        packagePage: 'Wybierz rodzaj opakowania',
    },
    link: {
        mainWebsite: `${process.env.VUE_APP_WEBSITE_URL}/pl`,
        configurationUrl: `${process.env.VUE_APP_CONFIGURATOR_URL}/pl/konfigurator/`,
        label: 'mindnotes.eu',
    },
    format: {
        title: 'Formaty',
        netto: 'wymiar netto bloku notesu',
    },
    meta: {
        title: 'Notesy reklamowe z logo Twojej firmy – konfigurator online',
        description:
            'Stwórz swój idealny notes i wybierz spośród ponad stu możliwości personalizacji: format, rodzaj narożników, grzbietu, papieru i nadruku, dobierz akcesoria oraz rodzaj wykończenia okładki. Możliwości jest tyle ile masz pomysłów. Sprawdź.',
    },
    loader: {
        close: 'Zamknij',
        info: {
            config: 'Pobieranie konfiguracji...',
            download: 'Generowanie pliku PDF...',
            deps: 'Prosze czekać...',
            summary: 'Przygotowujemy Twoja ofertę...',
        },
        error: {
            general: `<strong>Wystąpił błąd w działaniu aplikacji</strong> <br/>prosimy spróbować za chwilę.`,
            config:
                '<strong>Wystąpił błąd pobierania konfiguracji,</strong> <br/>konfiguracja nie istnieje lub nie jest już aktualna.',
            pdf:
                '<strong>Wystąpił błąd generowania pliku pdf</strong> <br/>prosimy spróbować ponownie.',
            deps:
                '<strong>Błąd komunikacji z serwerem</strong> <br/>prosimy spróbować ponownie.',
            summary:
                '<strong>Błąd komunikacji z serwerem</strong> <br/>prosimy spróbować ponownie.',
        },
    },
    cookies: {
        title: 'Szanujemy Twoją prywatność',
        content:
            'Pliki cookies pozwalają nam zagwarantować stabilne i poprawne funkcjonowanie strony i wyświetlać ofertę lepiej dostosowaną do Twoich potrzeb.',
        accept: 'Akceptuję',
        settings: 'Ustawienia plików cookies',
        saveAndContinued: 'Zapisz i kontynuuj',
        settingsBottomText:
            'By dowiedzieć się więcej na temat ciasteczek i sposobu ich wykorzystania, sprawdź naszą',
        settingsBottomPolicyText: 'politykę Cookies',
        settingsTitle: 'Ustawienia plików cookies',
        settingsDescription:
            'W zależności od funkcji i zastosowania pliki cookies możemy podzielić na cztery kategorie. Jeśli nie zostały wprowadzone żadne zmiany możesz przeglądać tę stronę korzystając z poniższych ustawień:',
        settingsNecessaryDescription:
            'Te ciasteczka są niezbędne do używania strony internetowej jej podstawowych funkcjonalności dlatego nie mogą zostać wyłączone.',
        settingsFunctionalDescription:
            'Te ciasteczka pomagają przechowywać Twoje preferencje na temat treści, które chcesz otrzymywać w prosty i łatwy sposób.',
        settingsEfficiencyDescription:
            'Te ciasteczka pomagają zaoszczędzić czas poprzez zwiększenie szybkości działania strony internetowej.',
        settingsAdvertisingDescription:
            'Te ciasteczka są niezbędne do oferowaniu Państwu promocji i ofert dopasowanych do Państwa potrzeb.',
        settingsNecessaryLabel: 'niezbędne',
        settingsFunctionalLabel: 'funkcjonalne',
        settingsEfficiencyLabel: 'wydajnościowe',
        settingsAdvertisingLabel: 'reklamowe',
        policyLink: 'https://mindnotes.eu/pl/polityka-prywatnosci',
    },
}
