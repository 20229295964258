<template>
    <transition name="fade">
        <CookiesPopup
            v-if="cookiesPopupOpen"
            :current-view="currentView"
            @onAccept="onAccept"
        />
    </transition>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import gtmInit from '@/plugins/gtm'
import GtmConsentsMixin from '@/mixins/GtmConsentsMixin'
import CookiesPopup from './CookiesPopup.vue'
export default {
    name: 'CookieWrapper',
    components: {
        CookiesPopup
    },
    mixins: [GtmConsentsMixin],
    computed: {
        ...mapGetters({
            cookiesTypes: 'getTypes',
            currentView: 'getCurrentView',
            cookiesPopupOpen: 'getActivePopup',
        }),
    },
    mounted() {
        this.initGtm()
        this.$nextTick(() => {
            if (this.$cookies.get('cookiesTypes')) {
                this.setCookiesTypes(this.$cookies.get('cookiesTypes'))
                this.setSessionId(this.$cookies.get('cookiesAcceptedUuid'))
                const interval = setInterval(() => {
                    if (
                        process.env.VUE_APP_GTM_ID &&
                        window.dataLayer.find((e) => e.event === 'gtm.load')
                    ) {
                        clearInterval(interval)
                        this.pushDataLayer('consent', 'update', {
                            ad_storage: this.cookiesTypes.advertisingConsent
                                ? 'granted'
                                : 'denied',
                            ad_user_data: this.cookiesTypes.advertisingConsent
                                ? 'granted'
                                : 'denied',
                            ad_personalization: this.cookiesTypes
                                .advertisingConsent
                                ? 'granted'
                                : 'denied',
                            analytics_storage: this.cookiesTypes.analyticConsent
                                ? 'granted'
                                : 'denied',
                            functionality_storage: this.cookiesTypes
                                .functionalConsent
                                ? 'granted'
                                : 'denied',
                            personalization_storage: this.cookiesTypes
                                .functionalConsent
                                ? 'granted'
                                : 'denied',
                            security_storage: this.cookiesTypes.necessaryConsent
                                ? 'granted'
                                : 'denied',
                        })
                        this.pushDataLayerEvent('cookie_consent_update')
                    }
                }, 200)
            } else {
                setTimeout(() => {
                    this.setActivePopup(true)
                })
            }
        })
    },
    methods: {
        ...mapMutations({
            setActivePopup: 'setActivePopup',
            setCookiesTypes: 'setTypes',
            setSessionId: 'setSessionId'
        }),
        onAccept() {
            this.setActivePopup(false)
        },
        initGtm() {
            if (!process.env.VUE_APP_GTM_ID) {
                return
            }
            gtmInit(process.env.VUE_APP_GTM_ID)
        },
    },
}
</script>
