export default {
    general: {
        loading: 'Loading',
        back: 'Retour au site',
        configurator: 'Configurateur des carnets',
        page: ' page',
        page2: ' feuilles',
        pages: ' feuilles',
        zoom: 'Agrandir la couverture extérieure',
        zoom2: "Agrandir le type d'impression",
        buttonView: 'Changer affichage',
        notfound: 'pays introuvable',
    },
    material: {
        title: 'Créez votre Mindnotes ',
        fsc: 'Mindnotes à partir de matériaux certifiés FSC<sup>®</sup>',
        standard: 'Mindnotes à partir de matériaux standards et écologiques',
        label: 'Démarrer la configuration',
        load: 'Charger les configurations: ',
        description:
            'Indiquez le code de configuration affiché sur la page de résumé pour le recharger. ',
        loadMobile: 'ou ajouter un numéro de configuration',
    },
    paper: {
        title: 'Couverture – matière',
        filter: 'Filtrer les matériaux par: ',
        color: 'Choisir la couleur',
        category: 'Choisir la catégorie',
        search: 'Saisissez le nom ou le numéro de matière',
        noResults: "Aucun resultat n'a pu être retrouvé",
        search2: 'chercher la matière',
        papier: 'Papier',
        okleina: 'Matière de couverture',
        placeholder: 'Saisissez le nom ou le numéro de matière de couverture',
        fallback:
            "Aucune matière n'a pu être retrouvée pour la configuration choisie",
    },
    config: {
        title: 'Votre configuration',
        subtitle:
            'Dans cette étape, vous pouvez vérifier et télécharger la configuration complète sans prix de vente. ',
        details: 'Afficher les détails de votre configuration ',
        button1: 'Télécharger le PDF',
        linkTitle: 'Lien vers votre configuration',
        linkDesc:
            'Copiez le code ou le lien pour réutiliser la même configuration dans les commandes suivantes.',
        button2: 'Copier le code',
        button3: 'Copier le lien',
        desc2:
            'Si vous souhaitez recevoir une offre avec les prix du marché, veuillez-vous connecter.',
        logowanko: 'Suivant',
        copyCode: 'Code copié',
        copyLink: 'Lien copié',
    },
    summary: {
        title: 'Résumé',
        save: 'Enregistrer au format PDF',
        edit: 'Éditer',
        goToForm: 'Accéder au formulaire',
        sendForm: 'Confirmer et envoyer',
        info: {
            send: 'Envoi du formulaire...',
            pdf: `"Pour enregistrer votre configuration au format PDF, cliquez sur le bouton ci-dessous"`,
            individual: `PromoNotes satisfait toutes ses commandes aux clients finaux exclusivement par l'intermédiaire de ses distributeurs, qui sont des agences de publicité`,
            form: `Pour recevoir une offre contraignante d'un distributeur, veuillez contacter PromoNotes en remplissant le formulaire`,
            agency: `Pour soumettre une demande officielle de devis et recevoir une offre commerciale contraignante, cliquez sur le bouton 'Accéder à l'offre'`,
            erp: `Après avoir cliqué dessus, vous quitterez le site web de Mindnotes et serez redirigé vers le portail client de PromoNotes. Vous y trouverez une offre commerciale contraignante pour votre configuration ainsi que l'historique des demandes de devis précédentes.`,
        },
    },
    pdf: {
        summary: 'Résumé de configuration',
        configurationNo: 'Numéro de calcul: ',
        code: 'Code de configuration: ',
        link: 'lien de configuration',
        file1: "Merci d'avoir configuré avec ",
        file2: 'succès votre carnet Mindnotes®',
        file3:
            "Nous invitons tous les revendeurs d’objets publicitaires à contacter l'équipe commerciale de PromoNotes. Il suffit d’envoyer",
        file4:
            "votre numéro de configuration à votre représentant commercial chez PromoNotes ou à l'adresse biuro@promonotes.pl,",
        file5: 'biuro@promonotes.pl',
        file6: 'et nous vous contacterons.',
        file7:
            "Si vous n'êtes pas un revendeur d’objets  publicitaires, veuillez contacter votre fournisseur de gadgets publicitaires. ",
        file71: '',
        file72:
            'Si vous ne coopérez pas jusqu’à maintenant avec un fournisseur qui offre des produits Mindnotes®, faites-le-nous savoir ',
        file73: 'et nous vous mettrons en contact avec notre distributeur.',
        file73Html:
            ' et nous vous mettrons en contact avec notre distributeur.',
        file8:
            "Les dimensions et graphiques générés sont fournis à titre indicatif et ne peuvent constituer le fondement d'une réclamation.",
        file9:
            "Le fabricant se réserve le droit de modifications techniques apportées aux produits individuels, et n'est pas responsable des ",
        file10:
            'erreurs dans le configurateur. La description des résumés de configuration et les graphiques générés dans le configurateur ',
        file11:
            'ne constituent pas une offre conforme aux dispositions du Code civil. ',
        footer: 'Promonotes Sp. z o.o. Tous droits réservés',
        page: 'Page ',
        data: 'Date: ',
    },
    form: {
        form: 'Formulaire',
        title: 'Se connecter',
        fill:
            'Pour recevoir une offre de prix, remplissez les champs du formulaire.',
        company: 'Nom de société',
        phone: 'Numéro de téléphone',
        email: 'E-mail',
        website: 'Adresse de site internet',
        street: 'Rue',
        houseNumber: 'Numéro du bâtiment/appartement',
        city: 'Ville',
        nip: 'TVA',
        zipcode: 'Code postal',
        country: 'Pays',
        name: 'Prénom',
        surname: 'Nom',
        position: 'Fonction',
        agencyName: 'Agence au service',
        agencyEmail: 'E-mail de l’agence',
        agencyAddress: "Coordonnées de l'agence",
        agencyInfo: '',
        message: 'Votre message concernant la commande',
        emailcopy:
            'Envoyez une copie de la configuration de mon notebook aux adresses mail fournies',
        consent1:
            '<span class="red">*</span> Je consens au traitement par la société PromoNotes sp. z o.o. de mes données personnelles contenues dans le formulaire afin de répondre à la demande adressée via le formulaire de contact. Les détails sur le traitement des données sont disponibles dans l\'onglet RGPD',
        consent2:
            "J'accepte de recevoir de la part de PromoNotes Sp. z o.o., par voie électronique à l'adresse e-mail que j'ai fournie dans le formulaire, des informations commerciales concernant les produits et services offerts par PromoNotes Sp. z o.o. au sens de la loi relative à la fourniture de services électroniques du 18 juillet 2002 (Journal des lois 2002 n° 144, texte 1204, soit Journal des lois de 2019, texte 123). Ce consentement est volontaire. Les détails sur le traitement des données sont disponibles dans l'onglet RGPD",
        consent3:
            "Je consens à l'utilisation d'équipements terminaux de télécommunications et de systèmes d'appel automatique au sens de la loi du 16 juillet 2004 - Loi sur les télécommunications (Journal des lois 2004 n° 171, texte 1800, soit Journal des lois de 2018, texte 1954), par rapport à l'adresse e-mail et le numéro de téléphone que j'ai fournie, à des fins de marketing direct, y compris pour établir un contact, présenter l'offre de PromoNotes spółka z ograniczoną odpowiedzialnością. Ce consentement est volontaire. Les détails sur le traitement des données sont disponibles dans l'onglet RGPD",
        send: 'Envoyer',
        description:
            'Si vous êtes déjà client Promonotes, connectez-vous en utilisant les mêmes données.',
        password: 'Mot de passe',
        password2: 'Répéter mot de passe',
        login: 'Se connecter',
        required: 'champ obligatoire',
        forgotPass: 'Avez-vous oublié votre mot de passe?',
        customerType: 'Type de client',
        individual: 'Client final',
        adAgency: 'Agence de publicité',
        add: 'Ajouter',
        goToOffer: "Accéder à l'offre",
        signInAndGoToOffer: "Créez un compte et accédez à l'offre",
        register: {
            title: 'Inscription',
            secondTitle: 'Vos coordonnées',
            send: 'S’inscrire',
        },
        passwordPage: {
            title: 'Avez-vous oublié votre mot de passe?',
            description:
                'Indiquez votre adresse e-mail que vous avez utilisé pour créer votre compte afin qu’un lien pour réinitialiser votre mot de passe soit envoyé.',
            label: 'E-mail',
            button: 'Envoyer',
            hello: 'Bonjour',
            company: 'de la société',
            tip:
                "Dans cette étape, vous allez créer le mot de passe de votre compte. N'oubliez pas que le mot de passe doit être efficace, il doit contenir de 8 à 16 caractères, lettres majuscules, chiffres et caractères spéciaux.",
            passWeak: 'Votre mot de passe est faible',
            passGood: 'Votre mot de passe est bon',
            passStrong: 'Votre mot de passe est fort',
            passResetTitle: 'Votre mot de passe a été réinitialisé',
            passResetDesc:
                'Pour vous connecter à votre compte, créez un nouveau mot de passe.',
            passResetSave: 'Enregistrer',
        },
        agency: {
            noAcc: "N'avez-vous pas encore de compte ?",
            register:
                "L'enregistrement est nécessaire afin de fournir des données pour la réalisation des commandes. Si vous avez déjà été client de Promonotes, vos données sont stockées dans notre base de données et il vous suffira de les confirmer.",
            createAcc: 'Créer votre compte',
        },
        male: 'Monsieur',
        female: 'Madame',
    },
    offer: {
        title: 'Offre',
        description:
            'Remplissez les champs obligatoires, et nous vous fournirons le prix du marché que nous recommandons.',
        prints: 'Tirage',
        price2: 'Prix d’achat',
        price1: 'Prix ​​de vente conseillé ',
        transportCost: 'Frais de port',
        legalInfo:
            "Les prix indiqués sont donnés à titre indicatif et ne constituent pas une offre au sens de l'article 66 par. 1 du Code civil. Nous nous réservons le droit de modifier les prix indiqués. Les prix indiqués sont des valeurs nettes et n'incluent ni taxes ni droits de douane. Pour une offre conjointe, accédez au RÉSUMÉ. ",
        country: 'Pays',
        countryOther:
            "Si votre pays ne figure pas dans la liste, veuillez sélectionner l'option AUTRE.",
        calculate: 'Calculer',
        orientation:
            'Le délai estimé de fabrication de la commande est de 14 à 21 jours à compter de la date de validation du BAT graphique.',
        currency: 'Monnaie',
        szt: 'pi.',
        other: 'AUTRE',
        warning: 'Conditions de livraison',
        addPrints: 'Ajouter une quantité',
        error: {
            form: `Erreur de validation du formulaire, veuillez remplir correctement les champs`,
        },
        info: {
            form: 'Vérification du formulaire...',
            valuation: 'Envoi du calcul...',
        },
    },
    sent: {
        title: 'Merci pour votre demande !',
        desc: 'Nous vous contacterons dès que possible',
        button: 'Nouvelle configuration',
    },
    modal: {
        coverSoft: 'Couverture souple en papier',
        coverSoftDescr:
            "La reliure la plus simple, agréable à l'œil et en même temps la plus économique de la collection Mindnotes®",
        coverHard: 'Couverture rigide recouverte de papier ou de matière',
        coverHardDescr:
            'Couverture rigide classique disponible dans une large gamme de revêtements en papier et en matières diverses.',
        coverSoft2: 'Couverture souple en matière',
        coverSoft2Descr:
            'La plus grande star de la collection Mindnotes®. Le carnet classique avec une couverture extrêmement flexible confectionnée uniquement en matière',
        coverHard2: 'Couverture rigide flexible en matère',
        coverHard2Descr:
            "Couverture classique rigide, mais en même temps agréablement flexible, proposée dans la collection Mindnotes®®  uniquement dans l'option matière.",
        cornerRound: 'Coins arrondis',
        cornerStraight: 'Coins droits',
        ridgeSoft: 'Dos souple',
        ridgeHard: 'Dos rigide',
        flyleaf:
            'Élément du carnet reliant la couverture au bloc de papier, généralement constitué du papier plus épais que le bloc intérieur.',
        pocket:
            'Une pochette pratique pour les documents installée à la troisième de la couverture',
        package1:
            'En vrac - les carnets sont emballés directement dans des cartons collectifs. Sur demande, des séparateurs de protection en papier sont placés entre les couches de carnets.',
        package2:
            'Standard – polybag individuel, à fermeture auto-adhésive ou avec perforation.',
        package3:
            "Écologique - le produit est emballé dans un film compostable, fabriqué à base d'amidon de maïs. Sachet imprimé avec perforation (logo 100% compostable).",
        marking1:
            "<strong>Embossage avec film </strong>- jusqu'à 50 cm<sup>2</sup> en un endroit unique",
        marking2:
            "<strong>Embossage petit sans film</strong> - jusqu'à 50 cm<sup>2</sup> en un endroit unique",
        marking3:
            "<strong>Embossage grand sans film </strong> -  jusqu'à 150 cm<sup>2</sup> en un endroit unique",
        paperTypeSoft1: 'Le matériau de couverture le plus répandu pour les cahiers standard est le papier souple :',
        paperTypeSoft2: 'carton couché recto verso 250 g/m2',
        paperTypeSoft3: 'Les matériaux de couverture les plus populaires pour les cahiers écologiques sont :',
        paperTypeSoft4: 'carton kraft non couché recyclé 300 g/m2',
        paperTypeSoft5: 'papier offset recyclé 300 g/m2',
        paperTypeTorino: 'Le matériau le plus populaire pour les couvertures de cahiers en reliure est le placage de Torino.',
        paperTypeHardVeneer1: 'Le matériau de couverture le plus populaire pour les cahiers standard est :',
        paperTypeHardVeneer2: 'papier craie 150 g/m2',
        paperTypeHardVeneer3: 'Les matériaux de couverture les plus populaires pour les cahiers écologiques sont :',
        paperTypeHardVeneer4: 'papier offset recyclé 160g/m2',
        paperTypeHardVeneer5: 'papier kraft non couché recyclé 120g/m2',
    },
    errors: {
        required: 'Sélectionnez les options obligatoires',
        cover: 'Sélectionnez au moins une option dans chaque catégorie',
        format: 'Sélectionnez le format',
        paper: 'Sélectionnez la matière de revêtement',
        coverMarking: 'Sélectionnez au moins une option dans chaque catégorie',
        packagePage: "Sélectionnez le type d'emballage",
    },
    link: {
        mainWebsite: `${process.env.VUE_APP_WEBSITE_URL}/fr`,
        configurationUrl: `${process.env.VUE_APP_CONFIGURATOR_URL}/fr/konfigurator/`,
        label: 'mindnotes.eu',
    },
    format: {
        title: 'Formats',
        netto: 'dimension nette du bloc-notes',
    },
    meta: {
        title:
            'Carnets promotionnels et professionnels sur mesure avec votre logo - configurateur en ligne',
        description: `Créez votre carnet de notes idéal et choisissez parmi plus de cent options de personnalisation : format, type de couverture, coins, dos, papier, type de personnalisation, accessoires. Il y a autant de possibilités que d'idées que vous avez. Consultez-le.`,
    },

    loader: {
        close: 'fermer',
        info: {
            config: 'Récupération de la configuration...',
            download: 'Génération de fichiers PDF...',
            deps: 'Téléchargement des dépendances...',
            summary: 'Génération de calcul...',
        },
        error: {
            general: `<strong>Il y a eu une erreur dans l'application</strong> <br/>veuillez réessayer bientôt.`,
            config: `<strong>Une erreur s'est produite lors du téléchargement de la configuration</strong> <br/>la configuration n'existe pas ou n'est plus valide.`,
            pdf: `<strong>Une erreur s'est produite lors de la génération du fichier</strong> <br/>veuillez réessayer.`,
            deps: `<strong>Une erreur s'est produite lors du téléchargement des dépendances</strong> <br/>veuillez réessayer.`,
            summary: `<strong>Une erreur s'est produite lors de la génération du calcul</strong> <br/>veuillez réessayer.`,
        },
    },
    cookies: {
        title: 'We respect your privacy',
        content:
            'Cookies allow us to guarantee a stable and correct functioning of the site and display an offer better suited to your needs.',
        accept: 'I accept',
        settings: 'Cookies settings',
        saveAndContinued: 'Save and Continue',
        settingsBottomText:
            'To learn more about cookies and how we use them, check out our',
        settingsBottomPolicyText: 'cookie policy',
        settingsTitle: 'Cookies settings',
        settingsDescription:
            'Depending on the function and use, we can divide cookies into four categories. If no changes have been made you can browse this site using the settings below:',
        settingsNecessaryDescription:
            'These cookies are necessary for the use of the website its basic functionality therefore they cannot be disabled.',
        settingsFunctionalDescription:
            'These cookies help store your preferences about the content you want to receive in a simple and easy way.',
        settingsEfficiencyDescription:
            'These cookies collect information about how users use the website, such as the most visited pages.',
        settingsAdvertisingDescription:
            'These cookies are necessary to offer you promotions and offers tailored to your needs.',
        settingsNecessaryLabel: 'essential',
        settingsFunctionalLabel: 'functional',
        settingsEfficiencyLabel: 'analytical',
        settingsAdvertisingLabel: 'advertising',
        policyLink: 'https://mindnotes.eu/de/datenschutzerklarung',
    },
}
